<template>
  <div class="tw-flex tw-w-full ">
    <loader :loading="loading" v-if="loading" />

    <div class="final-step" v-else>
        <landing-page-nav-bar/>
        <booking-steps />
      <div class="wrapper">
      <div class="back " @click="$router.push({name:'OneWayInputDetails'})"><v-icon>mdi-chevron-left</v-icon>Back</div>
      </div>
        <div class="rower">
            <div class="left">
                <div class="tw-flex tw-w-full lg:tw-items-center tw-flex-col lg:tw-flex-row tw-justify-end tw-pb-5">
                  <seat-timer/>
                </div>
                <checkout-left-side @handlePayment="handleSubmit" :payment-loading="submitLoading" />
            </div>
            <div class="right display-center ">
<!--                <select-insurance-plan />-->

                <checkout-passenger-summary />
                <button :style="{backgroundColor: companyData.companyColor ? companyData.companyColor : '#004AAD'}"
                        class="mt-9 cta-button tw-hidden"  @click="handleSubmit" >Submit Booking </button>
            </div>
        </div>
        <Footer/>
    </div>

   <modal section="print-ticket" :dialog="showBookingCompletedModal" :color="companyData.companyColor"
          @close="closePrintTicketModal"
          @printTicket="printBooking"
          :title="bookingCompletedModalTitle"
          :description="bookingCompletedModalDesc"/>

      <section slot="pdf-content" v-if="isPrinting">
        <booking-receipt  :booking="currentBooking" ref="customerReceipt" />
      </section>

    <paystack
        :amount="oneWayTotalAmount*100"
        :email="seats[0].travellerDetail.email"
        :paystackkey="getPayStackKey"
        :reference="reference"
        :callback="handlePaystackCallBak"
        :close="handleClosePaytackModal"
        :embed="false"
        :channels= "['card','bank_transfer']"
    >
      <div class="tw-hidden" ref="paystack">Pay</div>
    </paystack>


  </div>
</template>

<script>
    import BookingSteps from '@/components/reuseables/booking/OneWayBookingSteps.vue'
    import CheckoutLeftSide from '@/components/reuseables/booking/CheckoutLeftSide.vue'
    import CheckoutPassengerSummary from '@/components/reuseables/booking/CheckoutPassengerSummary.vue'
    import paystack from "vue-paystack";
    // import SelectInsurancePlan from '@/component/reusables/booking/SelectInsurancePlan.vue'
    import {mapGetters} from "vuex";
    import Loader from "@/components/reuseables/Loader";
    import {
      addBooking,
      getTripByTransportCompanyIdAndTripId,
      verifyPaystackPayment
    } from "@/services/api/APIService";
    import SeatTimer from "@/components/reuseables/booking/SeatTimer";
    import  defaultLogo from "@/assets/bluelogo.png"
    import Modal from "@/components/reuseables/Modal.vue";
    import BookingReceipt from "@/components/reuseables/booking/BookingReceipt.vue";
    import LandingPageNavBar from "@/components/reuseables/LandingPageNavBar.vue";
    import Footer from "@/components/reuseables/Footer.vue";
    import {createPaymentLog, getBookingByPaymentReference, reserveBooking} from "../../services/api/APIService";
    export default {
      name: "CheckOut",
        components: {
            Footer,
            LandingPageNavBar,
          BookingReceipt,
          Modal,
          SeatTimer,
          paystack,
          Loader,
            BookingSteps,
            CheckoutPassengerSummary,
            CheckoutLeftSide,
        },
      data(){
          return{
            loading : false,
            submitLoading : false,
            paymentUrl : "",
            showPaymentModal : false,
            defaultLogo,
            showBookingCompletedModal: false,
            bookingCompletedModalTitle: "processing ticket",
            bookingCompletedModalDesc: "please wait",
            currentBooking:{},
            isPrinting: false,
            paymentReference : "",
            logId:null,
            reference : null
          }
      },
      computed:{
        ...mapGetters("companyProfile", ["companyData"]),
        ...mapGetters("seatsSelection", ["oneWayTotalAmount","seats"]),
        ...mapGetters("tripAndTravellerDetails", ["tripAndTravellerDetails","userSelectedTrip"]),

        getIsBookedByCustomer(){
          let isBookedByCustomer = false
          let userData = sessionStorage.getItem("userData")
          if (userData){
            let data = JSON.parse(userData)
            if(data.userRole === 'customer'){
              isBookedByCustomer = true
            }
          }
          else {
            isBookedByCustomer = true
          }
          return isBookedByCustomer
        },

        getPayStackKey(){
          return process.env.VUE_APP_PAYSTACK_LIVE_PUBLIC_KEY
        }
      },
      methods:{
        getReference(){
          let text = "";
          let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

          for( let i=0; i < 10; i++ )
            text += possible.charAt(Math.floor(Math.random() * possible.length));
          this.reference = text;
        },
        async createPaymentLog(){
          let paymentLog = {}
          paymentLog.amount = this.oneWayTotalAmount

          paymentLog.discountCode=""

          paymentLog.currency="NGN"

          paymentLog.reference= this.reference

          paymentLog.service = "BusAcross"

          paymentLog.paymentProcessor = "paystack";

          paymentLog.purpose = "Booking";

          await  createPaymentLog(paymentLog).then(res =>{
            this.$refs.paystack.click()
            console.log(res.data)
            console.log("Done With creating Payment Log")
          })
        },
        async createLog(){
          this.getReference()
          this.submitLoading = true
          let bookingData = {}
          let travellerDetails = []
          let seats = ""
          this.seats.forEach(seat => {
            seats += seat.seatNumber
            if (this.seats.length > 1){ seats+= " ," }})
          bookingData.bookedBy = this.seats[0].travellerDetail.firstName
          bookingData.bookingType = "Online"
          bookingData.tripId = this.tripAndTravellerDetails.tripId
          bookingData.channel = "paystack"
          bookingData.domain = window.location.hostname
          bookingData.paymentReference = this.reference
          bookingData.tripFare = this.oneWayTotalAmount
          bookingData.selectedSeat = seats
          this.seats.forEach(seat => travellerDetails.push(seat.travellerDetail))
          bookingData.travellerDetails = travellerDetails
          bookingData.selectedSeats = this.seats
          await reserveBooking(bookingData).then(async res => {
            this.logId = res.data.generalLogId
            await this.createPaymentLog();
          }).catch(err => {
            this.submitLoading = false
            this.$displaySnackbar({
              message: err.response.data.details[0],
              success: false
            })

          }).finally(() => {
            this.submitLoading = false
          })
        },
        async getBooking(){
          let data = {}
          data.paymentReference = this.reference
          await getBookingByPaymentReference(data).then(res => {
            this.currentBooking = res.data
            this.bookingCompletedModalTitle = "booking completed"
            this.bookingCompletedModalDesc = "a booking confirmation have been sent to your email"
          }).catch(e =>{
            console.log(e)
            setTimeout(this.getBooking, 30000)
          })

        },
       async handleSubmitBooking(){
              let bookingData = {}
              let travellerDetails = []
              bookingData.bookedBy = this.seats[0].travellerDetail.firstName
             if (this.userSelectedTrip.tripRegion === 'crossCountry'){
                 let userSelectedCategory = JSON.parse(sessionStorage.getItem("userSelectedCategory"))
                 bookingData.bookingType = userSelectedCategory.type
             }
              bookingData.bookingType = "Online"
              bookingData.tripFare = this.oneWayTotalAmount * 100
              bookingData.mobileAgentId = this.companyData.id
              bookingData.tripId = this.tripAndTravellerDetails.tripId
              this.seats.forEach(seat => travellerDetails.push(seat.travellerDetail))
              bookingData.travellerDetails = travellerDetails
              bookingData.selectedSeats = this.seats
              bookingData.paymentReference = this.reference
              bookingData.domain = window.location.hostname
              bookingData.generalLogId = this.logId
              await addBooking(bookingData).then(res => {
                console.log(res.data)
                res.data.tripFare = res.data.tripFare * 100
                this.currentBooking = res.data
                this.showBookingCompletedModal = true
                this.submitLoading = false
              }).catch((err) => {
                this.submitLoading = false
                this.$displaySnackbar({
                  message: err.response.data.details[0],
                  success: false,
                });

              });

        },
        closePrintTicketModal(){
         this.showBookingCompletedModal = false
          sessionStorage.removeItem("tripDetails")
          sessionStorage.removeItem("userSelectedTrip")
          sessionStorage.removeItem("oneWaySeats")
          sessionStorage.removeItem("primaryTraveller")
          sessionStorage.removeItem("oneWayBookingShowTimer")
          sessionStorage.removeItem("minusFromTimer")
          this.$store.dispatch("seatsSelection/clearSeats")
          this.$store.dispatch("tripAndTravellerDetails/setUserSelectedTrip",{})
          this.$store.dispatch("tripAndTravellerDetails/updateTripAndTravellerDetails",{})
          if (this.getIsBookedByCustomer){
            this.$router.push({name : 'NewBooking'})
          }
          else {
            sessionStorage.setItem("dashboardLocationTracker", "booking")
            this.$router.push({name : 'BookingDashboard'})
          }
        },
        handleClosePaytackModal(){},
        handlePaystackCallBak(){
          if(!this.showBookingCompletedModal) {
            this.showBookingCompletedModal = true
          }
          this.submitLoading = false
          setTimeout(this.getBooking,30000)
        },
        printBooking() {
         this.isPrinting = !this.isPrinting
          this.$refs.customerReceipt.print()
        },
        async handleSubmit(){
          if(!this.logId) {
            await this.createLog()
          }
        },
        async handlePaystackPayment(){
           let seats = ""
           this.tripAndTravellerDetails.seats.forEach(seat => {
               seats += seat.seatNumber
             if (this.tripAndTravellerDetails.seats.length > 1){ seats+= " ," }})

          this.submitLoading = true
            let data = {}
            let travellerDetails = []
            data.departure = this.userSelectedTrip.routeDepartureTerminalName+" "+this.userSelectedTrip.routeDepartureCity+" "+this.userSelectedTrip.routeDepartureState
            data.destination = this.userSelectedTrip.routeDestinationTerminalName+" "+this.userSelectedTrip.routeDestinationCity+" "+this.userSelectedTrip.routeDestinationState
            data.selectedSeatNumbers = seats
            data.tripId = this.tripAndTravellerDetails.tripId
            data.amount = this.oneWayTotalAmount
            data.paymentReference = this.reference
            this.tripAndTravellerDetails.seats.forEach(seat => travellerDetails.push(seat.travellerDetail))
            data.receivingAccountName = this.companyData.paystackAccount.businessName
            data.receivingAccountNumber = this.companyData.paystackAccount.accountNumber
            data.paymentProcessor = "Paystack"
            data.paymentPurpose = "Booking"
            data.customerEmail = this.tripAndTravellerDetails.seats[0].travellerDetail.email
            data.travellers = travellerDetails
            data.generalLogId= this.logId
          await verifyPaystackPayment(this.companyData.userId, this.reference, data).then(async res => {
            if (res.data.detail === 'Transaction was successfully!!') {
              await this.handleSubmitBooking()
            }
            else {
              this.$displaySnackbar({
                message: "Payment was unsuccessful, give it a minute and try again.",
                success: false,
              });
            }
          }).catch(() => this.submitLoading = false)
          //
        },
      },
      created() {
        this.loading = true
        let userSelectedTrip = JSON.parse(sessionStorage.getItem("userSelectedTrip"))
        // let passengerDetail = JSON.parse(sessionStorage.getItem("passengerDetail"))
        let userSelectedCategory = JSON.parse(sessionStorage.getItem("userSelectedCategory"))
        if (Object.keys(userSelectedTrip).length){
          let data = {}
          data.transportCompanyId = userSelectedTrip.transportCompanyId
          data.tripId = userSelectedTrip.id
          getTripByTransportCompanyIdAndTripId(data).then(res =>{
              if (res.data.tripRegion === 'crossCountry' && Object.keys(userSelectedCategory).length){
                  res.data.tripAmount = userSelectedCategory.tripAmount
              }
            this.$store.dispatch("tripAndTravellerDetails/setUserSelectedTrip",res.data)
            // this.$store.dispatch("tripAndTravellerDetails/updateTripAndTravellerDetails",passengerDetail)
            // this.$store.dispatch("seatsSelection/updateCurrentSeats",passengerDetail.seats)
            this.$store.dispatch("seatsSelection/getTotalAmount",userSelectedTrip.tripAmount)

            this.loading = false
          }).catch(()=>this.loading = false)
        }
      }
    }
</script>

<style lang='scss' scoped>
.final-step{
  min-height: 100vh;
  width: 100%;
}
    .rower {
        display: flex;
        flex-direction: row;
        padding: 50px 100px;
        width: 100%;
         @media (max-width:1440px) {
            padding: 50px;
        }
             @media (max-width:1024px) {
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
        @media (max-width:576px) {
            padding: 4rem 1rem;
        }

    }

    .left {
        width: 100%;
      padding: 0 100px 100px;
      @media (max-width:1440px) {
            padding: 50px;
        }
             @media (max-width:1264px) {
               padding: 0 50px 0 0;
             }
        @media (max-width:1024px) {
            margin-bottom: 100px;
            padding: 0;
        }
    @media (max-width:768px) {
            margin-bottom:40px;

        }
    }

    .seats {
        padding: 0 !important;
    }

    .right {
        width: 574px;
        height: fit-content;
        flex-direction: column;
        padding: 50px   20px;
        background: #ffff;
        box-shadow: 4px 4px 35px rgba(79, 79, 79, 0.15);
        @media (max-width:576px) {
            width: 360px;
        }
    }
    .cta-button{
        width: 330px;
         font-family: Inter, serif;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  color: #ffffff;
  font-size: 16px;
  line-height: 22px;
  background: #004aac;
  border-radius: 8px;
  //width: 100%;
height: 53px;
          @media (max-width:576px) {
            width: 300px;
        }
    }

    .back{
      display: flex;
      align-items: center;
      font-family: 'Inter',sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #4F4F4F;
      width: max-content;
      text-transform: none;
      cursor: pointer;
    }

    .wrapper{
      width: 100%;
      padding: 0 10rem;

      @media screen and (max-width: 1024px) {
        padding: 1rem;
      }
    }

    .company-logo{
      width: auto;
      height: 40px;
    }
    ::v-deep .theme--light.v-footer {
      background-color: transparent !important;
    }
</style>